import {createSlice} from '@reduxjs/toolkit'
import type {PayloadAction} from '@reduxjs/toolkit'

export interface LayoutState {
    showLoader: boolean,
}

const initialState: LayoutState = {
    showLoader: false,
};

export const Layout = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        setShowLoader: (state, action: PayloadAction<any>) => {
            state.showLoader = action.payload;
        },
    },
})

// Action creators are generated for each case reducer function
export const {setShowLoader} = Layout.actions

export default Layout.reducer
