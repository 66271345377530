import React, { FC, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import QRCode from 'react-qr-code';
import { IQrModalProps } from '../../interfaces/Carts';
import { useSelector } from "react-redux";
import { RootState } from "../../components/Store";

const QRModal: FC<IQrModalProps> = ({ show, qr, handleClose }) => {
    const currentUser = useSelector((state: RootState) => state).user.detail;

    const myString = process.env.REACT_APP_POS_PROTOCOL + currentUser.domain_prefix + "." + process.env.REACT_APP_POS_URL + qr;
    const qrCodeRef = useRef<HTMLDivElement | null>(null); // Create a reference for the QR code

    const handlePrint = () => {
        if (qrCodeRef.current) {
            const printWindow = window.open('', '_blank');
            if (printWindow) {
                printWindow.document.write(`
                    <html>
                        <head>
                            <title>${myString}</title>
                            <style>
                                body { display: flex; justify-content: center; align-items: center; height: 90%; }
                                img { max-width: 100%; height: auto; }
                            </style>
                        </head>
                        <body>
                            <div>${qrCodeRef.current.innerHTML}</div>
                        </body>
                    </html>
                `);
                printWindow.document.close();
                printWindow.print();
                printWindow.close();
            }
        }
    };

    return (
        <Modal
            show={show}
            onHide={handleClose}
            centered
            className="modal-dialog-scrollable"
        >
            <Modal.Header closeButton>
                <Modal.Title>QR Code</Modal.Title>
            </Modal.Header>
            <Modal.Header>
                <h2><a href={myString} target={"_blank"}>{myString}</a></h2>
            </Modal.Header>
            <Modal.Body>
                <div ref={qrCodeRef}> {/* Attach the ref to this div */}
                    <QRCode
                        value={myString}
                        size={256}
                        bgColor="#ffffff"
                        fgColor="#000000"
                        style={{ margin: '0 auto', display: 'block' }}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handlePrint}>
                    Print QR Code
                </Button>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default QRModal;
