import React, {FC, useState} from 'react';
import {Modal, Button, Form} from 'react-bootstrap';
import {ICartData, IEditCartModalProps} from '../../interfaces/Carts';
import {update} from "../../components/requests/Cart";
import {setShowLoader} from "../../../redux/Layout";
import {useDispatch} from "react-redux";
import {updatePour} from "../../components/requests/Pour";

const EditCartModal: FC<IEditCartModalProps> = ({show, handleClose, handleEdit, cartData}) => {

    const dispatch = useDispatch();

    const [formData, setFormData] = useState<ICartData>(cartData);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    };

    const handleSubmit = async () => {

        dispatch(setShowLoader(true));

        handleEdit(formData);
        handleClose();

        const response = await update(formData);

        if (response) dispatch(setShowLoader(false));



    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Trailer Edit - Code #{formData.code}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group controlId="formName" className="mt-3">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                        type="text"
                        name="name"
                        value={formData.name || ''}
                        onChange={handleChange}
                    />
                </Form.Group>

                <Form.Group controlId="formDescription" className="mt-3">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                        type="text"
                        name="description"
                        value={formData.description || ''}
                        onChange={handleChange}
                    />
                </Form.Group>

                <Form.Group controlId="formAddress" className="mt-3">
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                        type="text"
                        name="address"
                        value={formData.address || ''}
                        onChange={handleChange}
                    />
                </Form.Group>

                <div className={'row'}>
                    <Form.Group controlId="formLog" className="mt-3 col-6">
                        <Form.Label>Log</Form.Label>
                        <Form.Control
                            type="number"
                            name="log"
                            value={formData.log?.toString() || ''}
                            onChange={handleChange}
                        />
                    </Form.Group>

                    <Form.Group controlId="formLat" className="mt-3 col-6">
                        <Form.Label>Lat</Form.Label>
                        <Form.Control
                            type="number"
                            name="lat"
                            value={formData.lat?.toString() || ''}
                            onChange={handleChange}
                        />
                    </Form.Group>

                </div>

                <Form.Group controlId="formTax" className="mt-3">
                    <Form.Label>Tax (%)</Form.Label>
                    <Form.Control
                        type="number"
                        name="tax"
                        value={formData.tax?.toString() || ''}
                        onChange={handleChange}
                    />
                </Form.Group>

                <Form.Group controlId="formBarId" className="mt-3">
                    <Form.Label>iPourIT (Bar ID)</Form.Label>
                    <Form.Control
                        type="number"
                        name="bar_id"
                        disabled={true}
                        value={formData.bar_id?.toString() || ''}
                        onChange={handleChange}
                    />
                </Form.Group>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditCartModal;
