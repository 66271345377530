import {FC, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {PageTitle} from "../../../_metronic/layout/core";
import EditModal from "./EditModal"; // Assuming EditModal exists in the same directory
import AddModal from "./AddModal"; // Assuming AddModal exists in the same directory
import {useDispatch, useSelector} from "react-redux";
import DataTable from "react-data-table-component";
import {RootState} from "../../components/Store";
import {TableData, IUnit} from "../../interfaces/Beverages";
import {DDrink} from "../../defaults/Beverages";
import {add as beverageAdd, deleteB, update} from "../../components/requests/Beverage";
import {setList as beverageSetList} from '../../../redux/Beverage';
import {setShowLoader} from '../../../redux/Layout';
import {toAbsoluteUrl} from "../../../_metronic/helpers";

const BeveragesPage: FC = () => {

    const dispatch = useDispatch();
    const intl = useIntl();

    const [showModal, setShowModal] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);
    const [selectedData, setSelectedData] = useState<TableData>(DDrink);
    const [tableData, setTableData] = useState<TableData[]>([]);
    const [loading, setLoading] = useState(true);
    const data = useSelector((state: RootState) => state);

    const fetchData = async () => {

        try {

            const sortedTableData = [...data.beverage.list].sort((a, b) => b.id - a.id);
            setTableData(sortedTableData);
            setLoading(false);

        } catch (error) {
            console.error("Error fetching data:", error);
        }

    };

    useEffect(() => {
        fetchData();
    }, [data]);

    const columns = [
        {
            name: "Logo",
            cell: (row: TableData) => (
                <div>
                    {row.logo ? (
                        <img
                            src={row.logo}
                            alt="Logo"
                            style={{width: "50px", height: "auto"}}
                        />
                    ) : (
                        <img src={toAbsoluteUrl('/media/logos/soft-drink_15542962.png')} alt="Logo" style={{width: "50px", height: "50px"}}/>
                    )}
                </div>
            ),
            ignoreRowClick: true,
            button: true,
        },
        {
            name: "Name",
            selector: (row: TableData) => row.name,
            sortable: true,
        },
        {
            name: "Default price",
            selector: (row: TableData) => "$"+row.price,
            sortable: true,
            right: true,
            className: "table-column-price",
        },
        {
            name: "Unit",
            selector: (row: TableData) => {
                const unit = data.beverage.units.find((u: IUnit) => u.id === row.unit_id);
                return unit ? unit.name : 'Unknown';
            },
            sortable: true,
        },
        {
            name: "Actions",
            cell: (row: TableData) => (
                <div className="d-flex justify-content-end align-items-center">
                    <button
                        className="btn btn-warning px-5 py-2 mx-2"
                        onClick={() => {
                            setSelectedData(row);
                            setShowModal(true);
                        }}
                    >
                        Edit
                    </button>
                </div>
            ),
            ignoreRowClick: true,
            button: true,
            width: "200px"
        },
    ];

    const handleDelete = (id: string) => {
        deleteB(id);
        const updatedData = tableData.filter((item) => item.id !== id);
        setTableData(updatedData);
    };

    const handleModalClose = () => setShowModal(false);
    const handleAddModalClose = () => setShowAddModal(false);

    const handleSave = async (updatedData: TableData, logo: File | null) => {

        dispatch(setShowLoader(true));

        const newBeverage = await update(updatedData,logo);

        if(newBeverage){dispatch(setShowLoader(false))}

        const updatedTableData = tableData.map((item) =>
            item.id === newBeverage.id ? newBeverage : item
        );

        dispatch(beverageSetList(updatedTableData));
        setShowModal(false);

    };

    const handleAdd =  async (newData: TableData, logo: File | null) => {

        dispatch(setShowLoader(true));

        const newBeverage = await beverageAdd(newData,logo);

        if(newBeverage){dispatch(setShowLoader(false))}

        const newTableData = [...tableData, newBeverage];

        dispatch(beverageSetList(newTableData));
        setShowAddModal(false);

    };

    return (
        <>
            <EditModal
                show={showModal}
                handleClose={handleModalClose}
                tableData={selectedData}
                handleSave={handleSave}
            />
            <AddModal
                show={showAddModal}
                handleClose={handleAddModalClose}
                handleAdd={handleAdd}
            />
            <div className="row gy-5 gx-xl-8">
                <div className="col-xl-6"></div>
            </div>
            <div className="row gy-5 gx-xl-8">
                <div className="col-xl-12">
                    <div className="card card-xxl-stretch mb-5 mb-xl-8">
                        <div className="card-header border-0 pt-5 d-flex justify-content-end w-100">
                            <button
                                className="btn btn-success mt-3"
                                onClick={() => setShowAddModal(true)}
                            >
                                Add New Beverage
                            </button>
                        </div>
                        <div className="card-body py-3">
                            <div className="table-responsive">
                                <DataTable
                                    columns={columns}
                                    data={tableData}
                                    progressPending={loading}
                                    keyField="id"
                                    pagination
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const BeveragesWrapper: FC = () => {
    const intl = useIntl();
    return (
        <>
            <PageTitle breadcrumbs={[]}>Beverages</PageTitle>
            <BeveragesPage/>
        </>
    );
};

export {BeveragesWrapper, BeveragesPage};
