import axios from 'axios';
import {BEVERAGE_URL} from "./Beverage";
import {setShowLoader} from "../../../redux/Layout";
import {useDispatch} from "react-redux";
export const URL = `${process.env.REACT_APP_API_URL}/pours`;

function makeJsonForRequest(postData: any){

    return {
        name: postData.name,
        position: postData.position,
        state: postData.state || '',
        price: postData.price || 0,
        cart_id: postData.cart_id,
        unit: postData.unit ? parseFloat(postData.unit) : undefined,
        full_capacity: postData.new_full_capacity ? parseFloat(postData.new_full_capacity) : undefined,
        current_capacity: postData.new_full_capacity ? parseFloat(postData.new_full_capacity) : undefined,
        beverage_id: postData.beverage_id || null
    };

}
export async function  addPour(postData: any) {
    const response = await axios.post(URL, makeJsonForRequest(postData));
    return response;
}
export async function updatePour(postData: any) {
    const response = await axios.put(URL + '/' + postData.id, makeJsonForRequest(postData));
    return response;
}
export async function deleteP(postDataId: any) {
    const response = await axios.delete(URL + '/' + postDataId, {})
    return response;
}
