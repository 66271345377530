import { configureStore } from '@reduxjs/toolkit'
import cartReducer from '../../redux/Cart'
import beverageReducer from '../../redux/Beverage'
import userReducer from '../../redux/User'
import layoutReducer from '../../redux/Layout'

export const Store = configureStore({
  reducer: {
    cart: cartReducer,
    beverage: beverageReducer,
    user: userReducer,
    layout: layoutReducer
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof Store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof Store.dispatch
