import React from "react";
import {BallTriangle,ThreeDots} from "react-loader-spinner";

const Loader = () => {
    const overlayStyle = {
        zIndex: 1000, // Nastavenie z-index na 1000
    };

    return (
        <div
            className="position-fixed top-0 start-0 w-100 h-100 bg-secondary bg-opacity-50 d-flex justify-content-center align-items-center"
            style={overlayStyle}
        >
            <ThreeDots
                visible={true}
                height="80"
                width="80"
                color="#4B5563"
                radius="9"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClass=""
            />
        </div>
    );
};

export default Loader;
